import Paper from "@material-ui/core/Paper"
import MuiToolbar from "@material-ui/core/Toolbar"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { NieuwsEdgeFragment } from "../../__generated__/graphql-gatsby"

interface IProps {
  next?: Maybe<NieuwsEdgeFragment>
  previous?: Maybe<NieuwsEdgeFragment>
}

const A = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: ${({ theme }) =>
    theme.transitions.create("color", {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    })};

  margin: ${({ theme }) => `0 ${theme.spacing(3)}px`};

  :hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: ${({ theme }) => `${theme.spacing(1)}px 0`};
  }
`

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing(2)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Text: React.FC<TypographyProps> = props => (
  <Typography variant="h6" component="span" {...props} />
)

const NextAndPrev: React.FC<IProps> = ({ next, previous }) => {
  if (!next && !previous) return null

  return (
    <Paper square elevation={2}>
      <Toolbar>
        {previous && (
          <A to={previous.uri || "/"}>
            <Text>← {previous.title}</Text>
          </A>
        )}

        {next && (
          <A to={next.uri || "/"}>
            <Text>{next.title} →</Text>
          </A>
        )}
      </Toolbar>
    </Paper>
  )
}

export default NextAndPrev
